@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0deg 0% 100%;
    --foreground: 0 0% 16.5%;

    --primary: 209 57% 80%;
    --primary-foreground: 0 0% 0%;

    --secondary: 72 71% 95%;
    --secondary-foreground: 0 0 0%;

    --scrollbar-track: #a0a0a0;
    --scrollbar-thumb: #646464;
  }

  * {
    font-family: "Poppins", sans-serif;
  }
}

/* width */
::-webkit-scrollbar {
  @apply w-1.5;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-secondary;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);

  @apply rounded-full;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
